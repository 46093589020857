//Note all endpoints must start with a / AND v1/institute is already present in base url

//BASIC AUTH CREDS
export const BASIC_AUTH_USERNAME = 'isportsqa';
export const BASIC_AUTH_PASSWORD = 'Maxx_qa@123';

// * Auth related apis
export const LOGIN = 'v1/institute/login';
export const LOGOUT = 'v1/institute/logout';
export const FORGOT_PASSWORD = 'v1/institute/forgot-password';
export const VERIFY_OTP = 'v1/institute/verify-otp';
export const RESEND_OTP = 'v1/institute/resend-otp';
export const RESET_PASSWORD = 'v1/institute/reset-password';

//Profile
export const PROFILE = 'v1/institute/profile';
export const CHANGE_PASSWORD = 'v1/institute/change-password';

//file upload
export const FILE_UPLOAD = 'v1/common/file-upload';

//Static content
export const GET_CONTENT = 'v1/user/content/list';

//Stripe payment
export const GET_PAYMENT_LINK = 'v1/institute/payment';

//* Coach Management Related Apis
export const COACH_LIST_GET = 'v3/institute/coach';
export const COACH_UPDATE_STATUS_PUT = (id: string) => `v1/institute/coach/status/${id}`;
export const COACH_DETAILS_GET = (id: string) => `v3/institute/coach/${id}`;
export const ADD_COACH_POST = 'v3/institute/coach/activation-code';
export const UNVERIFIED_COACH_LIST_GET = 'v3/institute/coach/unverified';
export const COACH_RESEND_ACTIVATION_CODE = 'v1/institute/coach/resend-code';
export const COACH_DELETE = (id: string) => `v1/institute/coach/${id}`;
export const COACH_TEAM_LIST_GET = (coachId: string) => `v1/institute/team/list?coachId=${coachId}`;
export const WATCH_LIST_GET = (coachId: string) => `v1/institute/coach/watchlist/${coachId}`;

//* Player Management Related Api
export const PLAYER_LIST_GET = 'v3/institute/player';
export const PLAYER_UPDATE_STATUS_PUT = (id: string) => `v1/institute/player/status/${id}`;
export const PLAYER_DETAILS_GET = (id: string) => `v3/institute/player/${id}`;
export const ADD_PLAYER_POST = 'v3/institute/player/activation-code';
export const UNVERIFIED_PLAYER_LIST_GET = 'v3/institute/player/unverified';
export const PLAYER_RESEND_ACTIVATION_CODE = 'v1/institute/player/resend-code';
export const PLAYER_DELETE = (id: string) => `v1/institute/player/${id}`;
export const PLAYER_TEAM_LIST_GET = (playerId: string) => `v1/institute/team/list?playerId=${playerId}`;

// * Dashboard related endpoints
export const DASHBOARD_STATS_GET = `v1/institute/dashboard`;

//* Team Management Related Apis
export const TEAM_LIST_GET = 'v1/institute/team/list';
export const TEAM_UPDATE_STATUS_PUT = (id: string) => `v1/institute/team/status/${id}`;
export const TEAM_DETAILS_GET = (id: string) => `v1/institute/team/${id}`;
export const ADD_TEAM_POST = `v1/institute/team/add`;
export const TEAM_UPDATE_PUT = (id: string) => `v1/institute/team/${id}`;
export const SPORT_LIST_GET = 'v1/institute/sports/list';
export const TEAM_DELETE = (id: string) => `v1/institute/team/${id}`;
export const GET_PLAYERS_BY_TEAM = (teamId: string) => `v1/institute/player?teamId=${teamId}`;

// * Payment Logs api
export const PAYMENT_LOGS_GET = `v1/institute/payment-log`;

// Common
export const GET_CATEGORIES = 'v1/categories';

// Player/Coach progress data
export const PROGRESS_GET = (id: string) => `v1/institute/tips-and-workout/progress/${id}`;
export const PROGRESS_LIST_GET = (id: string) => `v1/institute/tips-and-workout/progress-date-range/${id}`;
